export function newsFunc() {
	let newsTop = $('#newsTop').length;
  
	let resizecheck = $('#responsibleCheck').width();
	
	$(window).on('resize', function () {
	  resizecheck = $('#responsibleCheck').width();
	});
  
	if(newsTop){
  
	  let newsCategoryList = $('.newsCategoryList .activeSp');
	  
	  $(window).on('resize', function () {
		resizecheck = $('#responsibleCheck').width();
  
		//アコーディオン初期化
		if (resizecheck == '2') {
		  newsCategoryList.next('dd').css('display','none');
		} else if (resizecheck == '1') {
		  if (newsCategoryList.hasClass('categoryOpen')) {
			newsCategoryList.removeClass('categoryOpen');
		  }
		  newsCategoryList.next('dd').css('display','block');
		}
	  });
  
	  //アコーディオン初期化
	  if (resizecheck == '2') {
		newsCategoryList.next('dd').css('display','none');
	  } else if (resizecheck == '1') { 
		if (newsCategoryList.hasClass('categoryOpen')) {
		  newsCategoryList.removeClass('categoryOpen');
		}
		newsCategoryList.next('dd').css('display','block');
	  }
  
	  let newsArchivesList = $('.newsArchivesList .archivesBtn');
	  let newsFlg = 0;
	  newsArchivesList.on('click', function () {
		if (resizecheck == '2') {
		  $(this).stop().toggleClass('open');
		  $(this).next().stop().slideToggle(500);
		}
	  });
  
	  //マウスを乗せるかクリックで発火
	  $('#newsArchivesList').on({
		click: function () {
		  if (resizecheck == '1') {
			$(this).find('dd').stop().slideToggle(500);
			newsArchivesList.stop().toggleClass('open');
		  }
		},
		// mouseenter: function () {
		//   if (resizecheck == '1') {
		// 	$(this).find('dd').stop().slideDown(500);
		// 	newsArchivesList.stop().addClass('open');
		//   }
		// },
		// mouseleave: function () {
		//   if (resizecheck == '1') {
		// 	$(this).find('dd').stop().slideUp(500);
		// 	newsArchivesList.stop().removeClass('open');
		//   }
		// }
	  });
  
	  newsCategoryList.on('click', function () {
		$(this).stop().toggleClass('categoryOpen');
		$(this).next().stop().slideToggle(500);
	  });
  
	  newsCategoryList.on({
		'mouseover': function () {
		  if (responsibleCheck == '1') {
			newsFlg = 1;
			$(this).toggleClass('categoryOpen');
			$(this).next().stop().slideToggle(500);
		  }
		}
	  });
	}
  }