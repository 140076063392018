export function buttonActionFunc() {

	let pageNateItemCount = $('ul.custom li.page').length;
	let pageNateItem = $('ul.custom li.page');
	// console.log(pageNateItemCount)
	if(pageNateItemCount > 5){
	  let pageNateNum = $('ul.custom li.page.active').find('a').text() - 1;
	  let pageNateAllNum = pageNateItemCount - 1;
	  let pageNateItemLast = pageNateItemCount + 1
  
	//   console.log(pageNateNum);
	//   console.log(pageNateAllNum);
	//   console.log(pageNateItemLast)
	  
	  if(pageNateNum == 0){
		  pageNateItem.slice(5, pageNateAllNum).css('display', 'none');
		  if(pageNateItemLast >= 7){
			$("ul.custom li:nth-last-child(2)").addClass("skipPage");
		  }
	  } else if(pageNateNum == 1){
		$("ul.custom li:nth-last-child(2)").addClass("skipPage");
		  pageNateItem.slice(4, pageNateAllNum).css('display', 'none');
	  } else if(pageNateNum == 2){
		$("ul.custom li:nth-last-child(2)").addClass("skipPage");
		  pageNateItem.slice(0, pageNateNum - 2).css('display', 'none');
		  pageNateItem.slice(pageNateNum + 3, pageNateAllNum).css('display', 'none');
	  }  else if(pageNateNum == 3){
		pageNateItem.slice(1, pageNateNum - 2).css('display', 'none');
		pageNateItem.slice(pageNateNum + 3, pageNateAllNum).css('display', 'none');

		if(pageNateAllNum > pageNateNum + 3){
			console.log("OK")
			$("ul.custom li:nth-last-child(2)").addClass("skipPage");
		}
	  } else if(pageNateNum == pageNateAllNum){
		  pageNateItem.slice(1, pageNateNum - 4).css('display', 'none');
		  $("ul.custom li:nth-child(2)").addClass("backSkipPage");
	  } else if(pageNateNum == pageNateAllNum - 1){
		 pageNateItem.slice(1, pageNateNum - 2).css('display', 'none');
		 pageNateItem.slice(pageNateNum + 2, pageNateAllNum).css('display', 'none');
		 $("ul.custom li:nth-child(2)").addClass("backSkipPage");
	  }  else if(pageNateNum == pageNateAllNum - 2){
		pageNateItem.slice(1, pageNateNum - 2).css('display', 'none');
		pageNateItem.slice(pageNateNum + 2, pageNateAllNum).css('display', 'none');
		$("ul.custom li:nth-child(2)").addClass("backSkipPage");
	 }  else if(pageNateNum == pageNateAllNum - 3){
		pageNateItem.slice(1, pageNateNum - 2).css('display', 'none');
		$("ul.custom li:nth-child(2)").addClass("backSkipPage");
	 }else {
		 pageNateItem.slice(1, pageNateNum - 2).css('display', 'none');
		 pageNateItem.slice(pageNateNum + 3, pageNateAllNum).css('display', 'none');
		 $("ul.custom li:nth-last-child(2)").addClass("skipPage");
		 $("ul.custom li:nth-child(2)").addClass("backSkipPage");
	  }
	}
}