import 'slick-carousel';

export function productsFunc() {

  let products = $("#productsTop").length

  if(products){
	let productsSlide = $(".productsCatListMainImg").length
	let productsLink = $(".subNavScroll")
	let subNavBtn = $('#subNav .subNavBtn');
	let subNavCon = $("#subNavCon")
	subNavBtn.on('click', function () {
		$(this).stop().toggleClass('open');
		$(this).next().stop().slideToggle(500);
	});

	productsLink.on('click', function(){
		subNavCon.slideUp(500);
		subNavBtn.stop().toggleClass('open');
	});



	if(productsSlide){
		let slideItem = $(".productsCatList .list")
		slideItem.each(function(i){
			$(this).find(".productsCatListMainImg").attr("data-slick", i);
			$(this).find(".productsCatListThumImg").attr("data-slick", i);
		});

		$('.productsCatListMainImg').each(function(){
			//data-slickの値を取得
			let attr = $(this).attr('data-slick');
			$(this).slick({
				autoplay: false,
				arrows: false,
				lazyLoad: 'ondemand',
				asNavFor: '.productsCatListThumImg[data-slick="'+attr+'"]',
			});
			$('.productsCatListThumImg[data-slick="'+attr+'"]').slick({
				slidesToShow: 6,
				asNavFor: '.productsCatListMainImg[data-slick="'+attr+'"]',
				focusOnSelect: true,
				arrows: false,
				lazyLoad: 'ondemand',
				responsive:[
					{
						breakpoint: 768,
						settings:{
							slidesToShow: 4,
						},
					},
				],
			});
		});
	}
  }
}