export function contactFunc() {
  const contactPage = $('#contactForm').length;
  if (contactPage) {
    let selectIndex = '';
    let button = document.getElementById("sendBtn");
    button.disabled = true;

    // 必須項目名
    const productsBrand = $("[name='productsBrand']");
    const myName = $("[name='myName']");
    const myNameKana = $("[name='myNameKana']");
    const email = $("[name='email']");
    const emailCheck = $("[name='emailCheck']");
    const content = $("[name='content']");
    const agreeCheck = $("[name='agreeCheck']");

    // 必須項目定義
    const formRequired = [productsBrand, myName, myNameKana, email, emailCheck, content, agreeCheck];

    // 必須項目設定
    let requiredField = [];
    const requiredFieldSet = function () {
        requiredField = formRequired;
      for (let i = 0; i < requiredField.length; i++) {
        let requiredFieldType = requiredField[i].attr('type');
        if (requiredFieldType == 'radio' || requiredFieldType == 'checkbox') {
          requiredField[i].change(function () {
            fieldCheck(requiredField[i]);
            checkAllFields();
          });
        } else {
          requiredField[i].blur(function () {
            fieldCheck(requiredField[i]);
            checkAllFields();
          });
        }
      }
    }

    // すべての必須項目をチェックする関数
function checkAllFields() {
  let allFieldsFilled = true;
  let hasError = false;
  for (let i = 0; i < requiredField.length; i++) {
    if (requiredField[i].attr('type') == 'radio' || requiredField[i].attr('type') == 'checkbox') {
      // ラジオボタンやチェックボックスの場合、少なくとも1つが選択されているか確認
      if (!requiredField[i].is(':checked')) {
        allFieldsFilled = false;
        break;
      }
    } else {
      // テキスト入力の場合、値が空でないか確認
      if (requiredField[i].val() === '') {
        allFieldsFilled = false;
        break;
      }
    }

        // エラークラスのチェック
        if (requiredField[i].hasClass('error')) {
          hasError = true;
        }
  }

  if (allFieldsFilled) {
    if (!hasError) {
      button.disabled = false;
    } else {
      console.log('すべての必須項目が入力されましたが、エラーがあります。');
    }
  } else{
    button.disabled = true;
  }
}

    // 必須項目設定を呼び出す
    requiredFieldSet();
    // リロード時実行
    requiredFieldSet();

    const agreeCheckTxt = '「プライバシーポリシーに同意する」にチェックをしてください。';
    const emailRequiredTxt = '正しいメールアドレスを入力してください。';
    const emailDuplication = '上記フォームで入力したメールアドレスと異なります。';
    const telRequiredTxt = '10桁〜12桁の数字を入力してください。';

    let errorTxt = '';
    const fieldCheck = function (checkField, all = false) {
      let checkFieldValue = checkField.val();
      let checkFieldType = checkField.attr('type');
      let checkFieldTag = checkField[0].tagName;
      let checkFieldName = checkField.attr('name');
      let checkFieldDataName = checkField.attr('data-name');
      let checkFieldLabel = $('[for= ' + checkFieldName + ']');
      let checkFieldLabelTxt = checkFieldLabel.text().replace("※", "");;
      if (checkFieldType == 'radio') {
        errorTxt = checkFieldLabelTxt + 'は選択必須です。';
        if (!checkField.is(':checked')) {
          checkField.parents('.radioArea').addClass('error').next('.errorTxt').text(errorTxt);
        } else {
          checkField.parents('.radioArea').removeClass('error').next('.errorTxt').text('');
        }
      } else if (checkField == agreeCheck) {
        if (!checkField.is(':checked')) {
          checkField.parents('.checkboxArea').addClass('error').next('.errorTxt').text(agreeCheckTxt);
        } else {
          checkField.parents('.checkboxArea').removeClass('error').next('.errorTxt').text('');
        }
      } else if (checkField.hasClass('select')) {
        errorTxt = checkFieldLabelTxt + 'は選択必須です。';
        if (checkFieldValue == '') {
          checkField.addClass('error').parent('.selectBtn').next('.errorTxt').text(errorTxt);
        } else {
          checkField.removeClass('error').parent('.selectBtn').next('.errorTxt').text('');
        }
      } else if (checkFieldName == 'tradingProducts') {
        errorTxt = '取引希望製品は選択必須です。';
        if (!checkField.is(':checked')) {
          checkField.parents('.checkboxArea02').addClass('error').next('.errorTxt').text(errorTxt);
        } else {
          checkField.parents('.checkboxArea02').removeClass('error').next('.errorTxt').text('');
        }
      } else {
        errorTxt = checkFieldLabelTxt + 'は入力必須です。';
        if (checkFieldName == 'content' && selectIndex != 'inferior') {
          errorTxt = 'お問い合わせ内容は入力必須です。';
        }
        if (checkFieldValue == '') {
          checkField.addClass('error').next('.errorTxt').text(errorTxt);
        } else if (checkFieldType == 'email' && !checkFieldValue.match(/^([a-zA-Z0-9])+([a-zA-Z0-9\._-])*@([a-zA-Z0-9_-])+([a-zA-Z0-9\._-]+)+$/)) {
          checkField.addClass('error').next('.errorTxt').text(emailRequiredTxt);
        } else if (checkField == emailCheck && checkFieldValue != email.val()) {
          checkField.addClass('error').next('.errorTxt').text(emailDuplication);
        } else if (checkFieldType == 'tel' && !checkFieldValue.match('^[0-9]{10,12}$')) {
          checkField.addClass('error').next('.errorTxt').text(telRequiredTxt);
        } else if (checkFieldTag == 'TEXTAREA' && checkFieldValue.length >= 501) {
          errorTxt = '500文字以内で入力してください';
          checkField.addClass('error').next('.errorTxt').text(errorTxt);
        } else {
          checkField.removeClass('error').next('.errorTxt').text('');
        }
      }
    };

    let sendBtn = $('#sendBtn');
    // 各項目をサブミット処理でチェック（個人、法人フォーム）
    $('form').submit(function (e) {
      // 初期設定
      let set = 0;
      if (set == 0) {
        set = 1;
      } else {
        return false;
      }

      // 各項目をチェック（個人フォーム用）
      for (let i = 0; i < requiredField.length; i++) {
        fieldCheck(requiredField[i]);
      }

      // エラー箇所があればその場所までスクロール
      let offsetTxt, headerHeight;
      if ($('.errorTxt').text() != '') {
        offsetTxt = $('.error').offset().top;
        headerHeight = $('#header').outerHeight();
        $('html,body').animate({
          scrollTop: offsetTxt - headerHeight
        }, 500);
        return false;
      }

      // エラーがなければ二重送信禁止
      sendBtn.prop('disabled', 'true');
      setTimeout(function () {
        sendBtn.prop('disabled', '');
      }, 1000);
      return true;
    });

    // ラジオボタンチェック時入力欄有効可
    const radioBtn = document.querySelectorAll('.radioBtn');
    const radioField = document.querySelectorAll('.radioField');
    for (let i = 0; i < radioBtn.length; i++) {
      if (radioBtn[i].checked) {
        radioField[i].disabled = false;
      } else {
        radioField[i].disabled = true;
      }
    }
    $("input[type='radio']").on('click', function () {
      for (let i = 0; i < radioBtn.length; i++) {
        if (radioBtn[i].checked) {
          radioField[i].disabled = false;
        } else {
          radioField[i].disabled = true;
          radioField[i].value = '';
        }
      }
    });

    // チェックボックスチェック時入力欄有効可
    const checkBtn = document.querySelectorAll('.checkBtn');
    const checkField = document.querySelectorAll('.checkField');
    for (let i = 0; i < checkBtn.length; i++) {
      if (checkBtn[i].checked) {
        checkField[i].disabled = true;
        checkField[i].value = '';
      } else {
        checkField[i].disabled = false;
      }
    }
    $(".checkboxArea input[type='checkbox']").on('click', function () {
      for (let i = 0; i < checkBtn.length; i++) {
        if (checkBtn[i].checked) {
          checkField[i].disabled = true;
          checkField[i].value = '';
        } else {
          checkField[i].disabled = false;
        }
      }
    });

    // チェックボックスチェック時入力欄有効可
    const checkBtn02 = document.querySelectorAll('.checkBtn02');
    const checkField02 = document.querySelectorAll('.checkField02');
    for (let i = 0; i < checkBtn02.length; i++) {
      if (checkBtn02[i].checked) {
        checkField02[i].disabled = false;
      } else {
        checkField02[i].disabled = true;
        checkField02[i].value = '';
      }
    }
    $(".checkboxArea02 .checkBtn02").on('click', function () {
      for (let i = 0; i < checkBtn02.length; i++) {
        if (checkBtn02[i].checked) {
          checkField02[i].disabled = false;
        } else {
          checkField02[i].disabled = true;
          checkField02[i].value = '';
        }
      }
    });
  }
}
