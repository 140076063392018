import 'slick-carousel';
import { gsap } from "gsap";
import 'jquery.cookie';


export function introAnimeFunc(){
    let index = $("#indexTop").length
    let introAnimation = $.cookie('introAnimation');

    if(index){
      if (!introAnimation) {
        introAnimationFunc();
        $.cookie('introAnimation', 'true', { expires: 1 });
      } else{
        $(".top").removeClass("introAnimeStart");
        $(".introWrap").remove();
      }
      function introAnimationFunc() {
        // スクロールを禁止する関数
        disableScroll();
        const wait = ms => new Promise(resolve => setTimeout(() => resolve(), ms))
        wait(0)
          .then(() => {
            const introWrap = $(".introWrap")
            gsap.fromTo(
              introWrap, // アニメーション対象の要素
              {
                opacity: 1, // 開始時のスタイル
              },
              {
                onComplete: function() {
                  intervalId = setInterval(addActiveClass, 80);
                }
              }
            );
            return wait(5000)
          }).then(() => {
            const introWrap2 = $(".introWrap")
            introWrap2.addClass('introFinish')
            $(".top").removeClass("introAnimeStart");
            return wait(1000)
          }).then(() => {
            // スクロールを許可する関数
            enableScroll();
            return wait(500)
          }).then(() => {
            //念の為イントロエリアを削除
            $(".introWrap").remove();
          })
      }
  
      function disableScroll() {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
        document.body.style.overflow = 'hidden';
        window.onscroll = function () {
          window.scrollTo(scrollLeft, scrollTop);
        };
      }
      
      function enableScroll() {
        document.body.style.overflow = '';
        window.onscroll = null;
      }
  
  
      const pElements = document.querySelectorAll('#svgAnime path');
      const introLogo = document.getElementById('introLogo');
      let intervalId;
      let counter = 0;
      
      //文字のアニメーション、背景画像の変更など
      function addActiveClass() {
        pElements[counter].classList.add('active');
        counter++;
        if (counter === pElements.length) {
          introLogo.classList.add('active');
          clearInterval(intervalId); // intervalIdを使用してクリア
        }
      }
    }
}

export function idxFunc() {

  let index = $("#indexTop").length

  if(index){

    const slide01 = document.querySelector(".slide01");
    const slide02 = document.querySelector(".slide02");
    const slides01 = slide01.querySelectorAll(".slide");
    const slides02 = slide02.querySelectorAll(".slide");
    const slideButtons = document.querySelectorAll(".slideButtons button");
    let currentIndex01 = 0;
    let currentIndex02 = 1;
    let intervalId;
    let isAnimating = false;
    
    function showSlide(slideIndex, slideElement) {
      slideElement.querySelectorAll(".slide").forEach((slide, index) => {
        if (index === slideIndex) {
          gsap.to(slide, { zIndex: 1 });
        }
      });
    }
    
    function nextSlide() {
      if (isAnimating) return;
      // 各ボタンに対してループ
      slideButtons.forEach(button => {
        button.disabled = true;
      });
      isAnimating = true;
      const nextIndex01 = (currentIndex01 + 1) % slides01.length;
      const nextIndex02 = (currentIndex02 + 1) % slides02.length;

      // ボタンにcurrentクラスを設定
      slideButtons.forEach((btn, i) => {
        if (i === nextIndex01) {
          btn.classList.add("current");
        } else {
          btn.classList.remove("current");
        }
      });

      gsap.to(slides01[nextIndex01], {
        width: "100%",
        zIndex: 0,
        onComplete: () => {
          gsap.to(slides01[currentIndex01], {
            width: "0%",
            duration: 0.5,
            delay: 0.5,
            onComplete: () => {
              gsap.set(slides01[currentIndex01], {
                zIndex: -1,
                width: "100%",
                duration: 0.5,
                delay: 0.5,
              });
              gsap.set(slides01[nextIndex01], { 
                zIndex: 2, 
                delay: 1,
                onComplete: () => {
                  currentIndex01 = nextIndex01;
                  isAnimating = false;
                  slideButtons.forEach(button => {
                    button.disabled = false;
                  });
                }
               });
            },
          });
        },
      });

      gsap.to(slides02[nextIndex02], {
        width: "100%",
        zIndex: 0,
        onComplete: () => {
          gsap.to(slides02[currentIndex02], {
            width: "0%",
            duration: 0.5,
            delay: 0.5,
            onComplete: () => {
              gsap.set(slides02[currentIndex02], {
                zIndex: -1,
                width: "100%",
                duration: 0.5,
                delay: 0.5,
              });
              gsap.set(slides02[nextIndex02], { 
                zIndex: 2, 
                delay: 1,
                onComplete: () => {
                  currentIndex02 = nextIndex02;
                  isAnimating = false;
                }
              });
            },
          });
        },
      });
    }
    
    function startAutoSlideShow() {
      intervalId = setInterval(nextSlide, 5000); // 5秒ごとに次のスライドへ
    }
    
    function stopAutoSlideShow() {
      clearInterval(intervalId);
    }

    // 切り替えボタンにクリックイベントリスナーを追加
    slideButtons.forEach((button, index) => {
      button.addEventListener("click", () => {
        slideButtons.forEach(button => {
          button.disabled = true;
        });

        stopAutoSlideShow(); // クリックしたら自動スライドショーを停止

        const nextIndex01 = index;
        let nextIndex02 = index + 1;

        // nextIndex02がスライド数を超えた場合、0に戻す
        if (nextIndex02 >= slides02.length) {
          nextIndex02 = 0;
        }

        // ボタンのクリック時にcurrentクラスを設定
        slideButtons.forEach((btn, i) => {
          if (i === index) {
            btn.classList.add("current");
          } else {
            btn.classList.remove("current");
          }
        });

        gsap.to(slides01[nextIndex01], {
          width: "100%",
          zIndex: 0,
          onComplete: () => {
            gsap.to(slides01[currentIndex01], {
              width: "0%",
              duration: 0.5,
              delay: 0.5,
              onComplete: () => {
                gsap.set(slides01[currentIndex01], {
                  zIndex: -1,
                  width: "100%",
                  duration: 0.5,
                  delay: 0.5,
                });
                gsap.set(slides01[nextIndex01], { 
                  zIndex: 2,
                  delay: 1,
                  onComplete: () => {
                    currentIndex01 = nextIndex01;
                    isAnimating = false;
                    slideButtons.forEach(button => {
                      button.disabled = false;
                    });
                    startAutoSlideShow(); // 自動スライドショーを再開
                  }
                 });
              },
            });
          },
        });

        gsap.to(slides02[nextIndex02], {
          width: "100%",
          zIndex: 0,
          onComplete: () => {
            gsap.to(slides02[currentIndex02], {
              width: "0%",
              duration: 0.5,
              delay: 0.5,
              onComplete: () => {
                gsap.set(slides02[currentIndex02], {
                  zIndex: -1,
                  width: "100%",
                  duration: 0.5,
                  delay: 0.5,
                });
                gsap.set(slides02[nextIndex02], { 
                  zIndex: 2,
                  delay: 1,
                  onComplete: () => {
                    currentIndex02 = nextIndex02;
                    isAnimating = false;
                  },
                });
              },
            });
          },
        });
      });
    });

    
    // 最初に最初のスライドを表示
    showSlide(currentIndex01, slide01);
    showSlide(currentIndex02, slide02);
    slideButtons[currentIndex01].classList.add("current");
    startAutoSlideShow();
  
    
    
    
    
    

    let indexRecipeSlide = $("#indexRecipeSlide").length
    let indexRecipeSlideTarget = $("#indexRecipeSlide")

    if(indexRecipeSlide){
      indexRecipeSlideTarget.slick({
        autoplay: true,
        autoplaySpeed: 0,
        speed: 5000,
        cssEase: "linear",
        slidesToShow: 6,
        swipe: false,
        arrows: false,
        pauseOnFocus: false,
        pauseOnHover: false,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1.85,
            }
          }
        ]
      });

      indexRecipeSlideTarget.on('touchmove', function(event, slick, currentSlide, nextSlide){
        indexRecipeSlideTarget.slick('slickPlay');
        });
    }
  }
}