export function companyFunc() {
	let companyTop = $('#companyTop').length;

	if(companyTop){

		let resizecheck = $('#responsibleCheck').width();
		
		$(window).on('resize', function () {
			resizecheck = $('#responsibleCheck').width();

			if (resizecheck == '2') {
				originalSlideFunc();
				subNavScrollFunc();
			} else if (resizecheck == '1') {
			}
        });
		
		if (resizecheck == '2') {
			originalSlideFunc();
			subNavScrollFunc();
		  } else if (resizecheck == '1') { 
		}

		function subNavScrollFunc(){
		const windowHeight = window.innerHeight;

		const headerElement = document.getElementById("header");
		const headerHeight = headerElement.clientHeight;

		const subNavElement = document.getElementById("subNav");
		const subNavHeight = subNavElement.clientHeight;

		//1px未満の誤差で反応しない場合があるので+1
		const totalHeight = headerHeight + subNavHeight + 1;

		const percentageOfScreenHeight = (totalHeight / windowHeight) * 100;
		const wh = 100 - percentageOfScreenHeight;

			// 今回の交差を監視する要素
			const boxes = document.querySelectorAll(".aboutStoryListWrap .list");

			const options = {
				root: null,
				// rootMargin: "-20% 0px -80%",
				rootMargin: `-${percentageOfScreenHeight}% 0px -${wh}%`,
				threshold: 0
			};

			const observer = new IntersectionObserver(doWhenIntersect, options);
			boxes.forEach(box => {
				observer.observe(box);
			});

			/**
			 * 交差したときに呼び出す関数
			 * @param entries
			 */
			function doWhenIntersect(entries) {
				entries.forEach(entry => {
					if (entry.isIntersecting) {
						activateIndex(entry.target);
					}
				});
			}
			/**
			 * 目次を変える関数
			 * @param element
			 */
			function activateIndex(element) {
				const currentActiveIndex = document.querySelector("#storySubNavList .active");
				if (currentActiveIndex !== null) {
					currentActiveIndex.classList.remove("active");
				}
				const newActiveIndex = document.querySelector(`a[href='#${element.id}']`).parentElement;
				newActiveIndex.classList.add("active");

				//activeが入っているindex番号を取得
				let slideCurrentIndex = $(".pageLinkList.active").index();
				//クリック時のflagと数値を揃える
				slideFlag = slideCurrentIndex;
				updateButtonStates();
				$('.subNavList').animate({
					'margin-left' : -slideFlag*$('.pageLinkList').outerWidth()
				},0);
				
				let listShowNum = slideCurrentIndex + 1
				//カレント状態のみアルファ処理を排除
				$(`.aboutStoryListWrap .list:nth-child(${listShowNum})`).addClass("current")
				$(`.aboutStoryListWrap .list:not(:nth-child(${listShowNum}))`).removeClass("current")
			}
		}


		let slideFlag = 0;
		let pageLinkList = $(".pageLinkList").length;
		
		function originalSlideFunc() {
			updateButtonStates();
		
			$('.next').off('click').on('click', function () {
				if (slideFlag < pageLinkList - 1) {
					slideFlag++;
					updateButtonStates();
					$('.subNavList').animate({
						'margin-left': -slideFlag * $('.pageLinkList').outerWidth()
					},0);
					console.log(slideFlag);
				}
			});

			$('.prev').off('click').on('click', function () {
				if (slideFlag > 0) {
					slideFlag--;
					updateButtonStates();
					$('.subNavList').animate({
						'margin-left': -slideFlag * $('.pageLinkList').outerWidth()
					},0);
					console.log(slideFlag);
				}
			});
		}

		function updateButtonStates() {
			if (slideFlag === 0) {
				$('.prev').prop('disabled', true).hide();
			} else {
				$('.prev').prop('disabled', false).show();
			}
	
			if (slideFlag === pageLinkList - 1) {
				$('.next').prop('disabled', true).hide();
			} else {
				$('.next').prop('disabled', false).show();
			}
		}
	}
}