export function aboutFunc() {
	let aboutTop = $('#aboutTop').length;

	if(aboutTop){

		let resizecheck = $('#responsibleCheck').width();
		
		$(window).on('resize', function () {
			resizecheck = $('#responsibleCheck').width();

			if (resizecheck == '2') {
				slider01StopFunc();
				slider02Func();
				slider03Func();
			} else if (resizecheck == '1') {
				slider01Func();
				slider02StopFunc();
				slider03StopFunc();
			}
        });
		
		if (resizecheck == '2') {
			slider01StopFunc();
			slider02Func();
			slider03Func();
		  } else if (resizecheck == '1') { 
			slider01Func();
			slider02StopFunc();
			slider03StopFunc();
		}

		function slider01Func(){
			let slider01 = $(".slider01")
			slider01.slick({
			  slidesToShow: 3,
			  infinite:true,
			  autoplaySpeed:0,
			  autoplay:true,
			  speed: 6000,
			  cssEase: "linear",
			  arrows: false,
			  pauseOnFocus: false,
			  pauseOnHover: false,
			  pauseOnDotsHover: false,
			});
		
			slider01.on('touchmove', function(event, slick, currentSlide, nextSlide){
				slider01.slick('slickPlay');
			});
		}
		function slider01StopFunc(){
			$(".slider01.slick-initialized").slick('unslick');
		}



		function slider02Func(){
			let slider02 = $(".slider02");
			slider02.slick({
				slidesToShow: 1.5,
				infinite:true,
				autoplaySpeed:0,
				autoplay:true,
				speed: 6000,
				cssEase: "linear",
				arrows: false,
				pauseOnFocus: false,
				pauseOnHover: false,
				pauseOnDotsHover: false,
			  });
		  
			  slider02.on('touchmove', function(event, slick, currentSlide, nextSlide){
				slider02.slick('slickPlay');
			  });
		}

		function slider02StopFunc(){
			$(".slider02.slick-initialized").slick('unslick');
		}

		function slider03Func(){
			let slider03 = $(".slider03");
			slider03.slick({
				slidesToShow: 1.5,
				infinite:true,
				autoplaySpeed:0,
				autoplay:true,
				speed: 6000,
				cssEase: "linear",
				arrows: false,
				pauseOnFocus: false,
				pauseOnHover: false,
				pauseOnDotsHover: false,
				rtl: true,
			});
		  
			slider03.on('touchmove', function(event, slick, currentSlide, nextSlide){
				slider03.slick('slickPlay');
			});
		}

		function slider03StopFunc(){
			$(".slider03.slick-initialized").slick('unslick');
		}
	}
}