export function recipeFunc() {

	let recipe = $("#recipeTop").length
  
	if(recipe){

        let resizecheck = $('#responsibleCheck').width();
	
        $(window).on('resize', function () {
          resizecheck = $('#responsibleCheck').width();
        });

        const searchBox = document.getElementById('searchBox');
        const recipeNav = document.getElementById('recipeNav');
        const searchWrap = document.getElementById('searchWrap');
        const contentsSearch = document.getElementById('contentsSearch');

        searchBox.addEventListener('click', function (event) {
            if (!searchBox.classList.contains('searchOn')) {
                event.preventDefault(); // ボックスが閉じている場合、検索を実行せずに拡大します
                searchBox.classList.toggle('searchOn');
                recipeNav.classList.toggle('searchOn');
                searchWrap.classList.toggle('searchOn');
                contentsSearch.focus(); // フォーカスを検索ボックスに設定
            }
        });

        // ボックス内の要素がクリックされてもボックスを閉じないようにする
        searchBox.addEventListener('click', function (event) {
            event.stopPropagation();
        });

        // 全体をクリックしたときにボックスを閉じる
        document.addEventListener('click', function (event) {
            if (event.target !== searchBox) {
                searchBox.classList.remove('searchOn');
                recipeNav.classList.remove('searchOn');
                searchWrap.classList.remove('searchOn');
            }
        });


        let recipeCategoryList = $('.recipeCategoryList .active');
        $(window).on('resize', function () {
            resizecheck = $('#responsibleCheck').width();
      
            //アコーディオン初期化
            if (resizecheck == '2') {
              recipeCategoryList.next('dd').css('display','none');
            } else if (resizecheck == '1') {
              if (recipeCategoryList.hasClass('categoryOpen')) {
                recipeCategoryList.removeClass('categoryOpen');
              }
              recipeCategoryList.next('dd').css('display','block');
            }
          });

    //アコーディオン初期化
	  if (resizecheck == '2') {
		  recipeCategoryList.next('dd').css('display','none');
	  } else if (resizecheck == '1') { 
		if (recipeCategoryList.hasClass('categoryOpen')) {
		  recipeCategoryList.removeClass('categoryOpen');
		}
		  recipeCategoryList.next('dd').css('display','block');
	  }

    recipeCategoryList.on('click', function () {
      if (resizecheck == '2'){
        $(this).stop().toggleClass('categoryOpen');
        $(this).next().stop().slideToggle(500);
      }
    });
  
	  recipeCategoryList.on({
		'mouseover': function () {
		  if (responsibleCheck == '1') {
			newsFlg = 1;
			$(this).toggleClass('categoryOpen');
			$(this).next().stop().slideToggle(500);
		  }
		}
	  });
	}
  }