const header = document.getElementById("header");

const indexTop = $('#indexTop').length;
const ageConfirmTop = $("#ageConfirmTop").length;
const anniversaryTop = $("#anniversaryTop").length;

const contentsPage = $('#contentsTop').length;
const subNavPage = $('#subNav').length;
const subNav = document.getElementById('subNav');

const navBtn = $('#navBtn');
const navMenu = $('#navMenu');

let menuOpenFlg = 0;
let scrTopFlg = 0;
let scrollPosition;
export function headerFunc() {
  navBtn.on('click', function () {
    navBtn.toggleClass('isOpen');
    if (navBtn.hasClass('isOpen')) {
      scrollPosition = $(window).scrollTop();
      menuOpenFlg = 1;
      navMenu.addClass('isShow');
      $('.headerContents').addClass('isShow');
      $('body').addClass('fixedBody').css({
        'top': -scrollPosition
      });
    } else {
      menuOpenFlg = 0;
      navMenu.removeClass('isShow');
      $('.headerContents').removeClass('isShow');
      $('body').removeClass('fixedBody').css({
        'top': 0
      });
      window.scrollTo(0, scrollPosition);
    }
  });
}

export function headerScrFunc() {
  console.log('スクロールで呼ばれます。');
}